<template>
	<v-menu v-model="menu" :close-on-content-click="false" full-width lazy min-width="290px" offset-y transition="scale-transition" @input="onMenuSwitchState">
		<template v-slot:activator="{ on }">
			<v-text-field v-model="model" :clearable="clearable" :label="label" prepend-inner-icon="event" readonly v-on="on"></v-text-field>
		</template>
		<v-date-picker
			ref="picker"
			v-model="date"
			color="primary"
			:min="minDate"
			:max="youngestDate"
			no-title
			:picker-date="date"
			reactive
			@input="onInput"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'WYearPicker',
	props: {
		clearable: {
			default: false,
			required: false,
			type: Boolean
		},
		label: {
			default: null,
			required: false,
			type: String
		},
		min: {
			default: null,
			required: false,
			type: Number
		},
		value: {
			default: null,
			required: false,
			type: Number,
		}
	},
	data: function () {
		return {
			date: null,
			menu: false
		}
	},
	computed: {
		minDate: function() {
			let minDate = undefined

			if (this.min) {
				const date = new Date()
				date.setFullYear(this.min - 1)
				date.setMonth(11)
				date.setDate(31)
				minDate = date.toISOString()
			}

			return minDate
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		youngestDate: function () {
			const dateNow = new Date()
			if (this.value) {
				dateNow.setFullYear(this.value + 20)
			} else {
				dateNow.setFullYear(dateNow.getFullYear() + 20)
			}
			return dateNow.toISOString().substring(0, 10)
		}
	},
	watch: {
		value: {
			handler: function (value) {
				if (!value) {
					this.date = null
				} else if (
					!this.date 
					|| Number.parseInt(this.date.substring(0, 4)) !== value
				) {
					const date = new Date()
					date.setFullYear(this.value)
					this.date = date.toISOString().substring(0, 10)
				}
			}
		}
	},
	mounted: function () {
		if (this.value) {
			const date = new Date()
			date.setFullYear(this.value)
			this.date = date.toISOString().substring(0, 10)
		}
	},
	methods: {
		onInput: function () {
			const date = new Date(this.date)
			const year = date.getFullYear()
			this.setValue(year)
			this.menu = false
		},
		onMenuSwitchState: function () {
			if (this.menu) {
				this.$nextTick(() => {
					this.$refs.picker.activePicker = 'YEAR'
					setTimeout(() => {
						this.scrollToCurrentValue()
					}, 100)
				})
			}
		},
		setValue: function (year) {
			this.$emit('input', year)
		},
		scrollToCurrentValue: function () {
			let year = this.value ? this.value.toString() : (new Date()).getFullYear().toString()
			let actives = document.getElementsByClassName('active')
			if (actives) {
				let elementToScrollTo = null
				let elementParent = null
				for (let i= 0; i < actives.length; i++) {
					if (actives[i].textContent === year) {
						elementToScrollTo = actives[i]
						elementParent = elementToScrollTo.parentNode
					}
				}
				let topPos = elementToScrollTo.offsetTop
				elementParent.scrollTop = topPos
			}
		}
	}
}
</script>
